<template>
  <v-data-table
    :headers="headers"
    :items="obCollection.getModelList()"
    :total="serverItemsLength"
    :last-page="serverLastPage"
    :loading="bLocalLoading"
    hide-default-header
    hide-default-footer
    class="dt-listing"
  >
    <template #top>
      <div class="text-h5 ml-2 mt-2">{{ $t("quotes") }}</div>
    </template>

    <template v-slot:[`item.currency_name`]="{ item }">
      <name-with-avatar
        :path="getFlag(item.currency.code)"
        :name="item.currency.name"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CurrencyRatesMixin from "@/modules/currencyrates/mixins/CurrencyRatesMixin";
import { DataTableHeader } from "vuetify";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import { get } from "lodash";

@Component({
  components: { NameWithAvatar },
})
export default class CurrencyTable extends Mixins(CurrencyRatesMixin) {
  hideActionsHeader = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "currency", value: "currency_name" },
      { text: "rate", value: "rate" },
    ];
    this.setDTHeaders(arHeaders);
    this.onSetFilters({ group: 1 });

    if (get(this.filters, `${this.modelClassName}.group`)) {
      this.index();
    }
  }

  onRegisterEvents() {
    this.addEvent("filters.change", (sModel) => {
      if (sModel === this.modelClassName) {
        this.index();
      }
    });
    this.addEvent(`${this.sModelName}.after.save`, this.index);
  }

  getFlag(sCode: string) {
    return require(`@icon/flags/${sCode.toLowerCase()}.png`);
  }
}
</script>
