<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" lg="8">
        <data-table
          :headers="headers"
          :items="items"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="currencies"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`item.name`]="{ item }">
            <currency-preview v-if="item.rate_value === 1" :item="item" />
            <currency-rate-history-dialog v-else :currency="item.code" />
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template v-slot:[`item.is_default`]="{ item }">
            <active-icon :active="item.is_default" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
      <v-col cols="12" lg="4">
        <currencies-table v-if="items.length" />
      </v-col>
    </v-row>
    <currencies-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CurrenciesMixin from "@/modules/currencies/mixins/CurrenciesMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CurrenciesForm from "@/modules/currencies/components/Form.vue";
import CurrenciesTable from "@/modules/currencyrates/components/CurrenciesTable.vue";
import { DataTableHeader } from "vuetify";
import CurrencyRateHistoryDialog from "@/modules/currencyrates/components/CurrencyRateHistoryDialog.vue";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";

@Component({
  components: {
    CurrencyPreview,
    CurrencyRateHistoryDialog,
    ActiveIcon,
    CurrenciesForm,
    CurrenciesTable,
  },
})
export default class CurrenciesList extends Mixins(CurrenciesMixin) {
  isList = true;

  get items() {
    return this.obCollection && this.obCollection.length
      ? this.obCollection.getModelList()
      : [];
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      {
        text: "isdefault",
        value: "is_default",
        class: "mw-150",
        sortable: false,
      },
      { text: "symbol", value: "symbol", class: "mw-150", sortable: false },
      { text: "code", value: "code", class: "mw-150", sortable: false },
      // { text: "rate", value: "rate", class: "mw-150", sortable: false },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }
}
</script>
